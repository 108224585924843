import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';
import Banner from '../genericas/Banner';

class Servicios extends React.Component{

    constructor(){
        super();

        this.state={
            dom:getVar()[0],
            Url:"https://paginas.virextec.com/archivos/",
            org:getVar()[1],
            web:getVar()[2],
            webSite:{},
            productos:[]
        }
    }

    componentDidMount = () =>{

        this.miData();

    }

    miData = async () =>{
        const{
            dom, org, web, webSite, productos
        }=this.state;

        if(sessionStorage.getItem('empresa')==null){
            return await getData(0, dom, org, web,0,0,0,0, 0).then(res =>{
                sessionStorage.setItem('empresa', JSON.stringify(res.data));
                console.log(res.data)               

                this.setState({
                    webSite: res.data.web[0],
                    productos: res.data.proAll
                });
            })
        }else {
            console.log('ya hay datos cargados');
            let data = JSON.parse(sessionStorage.getItem('empresa'));

            this.setState({
                webSite: data.web[0],
                productos: data.proAll
            });
        }
    }

    render(){

        const {
            webSite, productos, Url
        }=this.state;

        const displayProductos = (productos || []).map((item)=>{
            return(               

                <div key={item?.ProID} className="col-lg-4 col-md-6 mb-5">
                    <div class="ts-service-box">
                        <div class="ts-service-image-wrapper">
                            <img loading="lazy" class="w-100" src="archivos/images/services/service1.jpg" alt="service-image" />
                        </div>
                        <div class="d-flex">
                            <div class="ts-service-box-img">
                                <img loading="lazy" src="archivos/images/icon-image/service-icon1.png" alt="service-icon" />
                            </div>
                            <div class="ts-service-info">
                                <h3 class="service-box-title"><Link to={`servicios/${item?.ProID}`}>{item?.ProNom}</Link></h3>
                                <p>{item?.ProDesCor}</p>
                                <a class="learn-more d-inline-block" href="service-single.html" aria-label="service-details"><i class="fa fa-caret-right"></i>Ver Mas</a>
                            </div>
                        </div>
                    </div>
                </div>
                        
            )
        })
        
        return(
            <>
                <Banner />

                <section id="main-container" className="main-container pb-2">
                    <div className="container">
                        <div className="row">

                        {displayProductos}

                        </div>
                    </div>
                </section>

                
            </>
        )
    }
}

export default Servicios;