import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom';
import Menu from './components/menu/Menu';
import Inicio from './components/inicio/Inicio';
import Contacts from './components/contact/Contacts';
import Servicios from './components/servicios/Servicios';
import Clientes from './components/clientes/Clientes';
import Proyectos from './components/proyectos/Proyectos';
import Nosotros from './components/nosotros/Nosotros';

class App extends React.Component{

  render(){
    const NoMatch = () =>{
      const { pathname } = useLocation()

      return(
        <h3>No Match for <code>{pathname}</code></h3>
      )
    }

    return(
      <>
        <Router>
          <Menu />
          <Switch>
            <Route path='/' exact component={Inicio} />
            <Route path='/contacto' exact component={Contacts} />
            <Route path='/servicios' exact component={Servicios} />
            <Route path='/clientes' exact component={Clientes} />
            <Route path='/proyectos' exact component={Proyectos} />
            <Route path='/nosotros' exact component={Nosotros} />
          </Switch>
        </Router>
      </>
    )
  }

}

export default App;