import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';
import Footer from '../genericas/Footer';

class Inicio extends React.Component{

    constructor(){
        super();

        this.state={
            dom:getVar()[0],
            Url:"https://paginas.virextec.com/archivos/",
            org:getVar()[1],
            web:getVar()[2],
            webSite:{},
            servicios:[]
        }
    }

    componentDidMount = () => {
        this.miData();
    }

    miData = async () =>{
        const{
            dom, org, web, webSite, servicios
        }=this.state;

        if(sessionStorage.getItem('empresa')==null){
            console.log('no hay datos....');
            return await getData(0, dom, org, web,0,0,0,0, 0).then(res =>{
                sessionStorage.setItem('empresa', JSON.stringify(res.data));
                console.log(res.data)               

                this.setState({
                    webSite: res.data.web[0],
                    productos: res.data.proAll
                });
            })
        }else{
            console.log('ya hay datos cargados');
            let data = JSON.parse(sessionStorage.getItem('empresa'));

            console.log(data)

            this.setState({
                webSite: data.web[0],
                productos: data.proAll
            });
        }
    }

    render(){
        const {
            webSite, Url, productos
        }=this.state;

        const displayProductosIzquierda = (productos || []).map((item)=>{
            if(item.NivID==='8'){
                return(
                    <div key={item?.ProID} className="ts-service-box d-flex">
                        <div className="ts-service-box-img">
                            <img loading="lazy" src="archivos/images/icon-image/service-icon1.png" alt="service-icon"></img>
                        </div>
                        <div className="ts-service-box-info">
                            <h3 className="service-box-title"><Link to={`producto${item?.ProID}`}/>{item?.ProNom}</h3>
                            <p>{item?.ProDesCor}</p>
                        </div>
                    </div>

                )
            }else return null;
            
        });

        const displayProductosDerecha = (productos || []).map((item)=>{
            if(item.NivID=='9'){
                return(
                    <div key={item?.ProID} className="ts-service-box d-flex">
                        <div className="ts-service-box-img">
                            <img loading="lazy" src="archivos/images/icon-image/service-icon5.png" alt="service-icon"></img>
                        </div>
                        <div className="ts-service-box-info">
                            <h3 className="service-box-title"><Link to={`producto${item?.ProID}`}/>{item?.ProNom}</h3>
                            <p>{item?.ProDesCor}</p>
                        </div>
                    </div>

                )
            }else return null;
            
        });


        return(
            <>
            <section id="ts-service-area" className="ts-service-area pb-0">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h2 className="section-title"> Somos especialista en </h2>
                            <h3 className="section-sub-title"> Que hacemos </h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            {displayProductosIzquierda}
                        </div>

                        <div className="col-lg-4 text-center">
                            <img loading="lazy" className="img-fluid" src="archivos/images/services/service-center.jpg" alt="service-avater-image" />
                        </div>

                        <div className="col-lg-4 mt-5 mt-lg-0 mb-4 mb-lg-0">
                            {displayProductosDerecha}
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            </>

        )
    }

}

export default Inicio;
