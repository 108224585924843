import React from 'react';
import { getData, getVar } from '../../services/WebService';
import { Link } from 'react-router-dom';
import Banner from '../genericas/Banner';

class Contacts extends React.Component{

    constructor(){
        super();

        this.state={
            dom:getVar()[0],
            Url:"https://paginas.virextec.com/archivos/",
            org:getVar()[1],
            web:getVar()[2],
            website: {},
            sections: []
        }
    }

    componentDidMount = () => {
        this.miData();
    }

    miData = async () =>{
        const{
            dom, org, web, webSite, sections
        }=this.state;

        if(sessionStorage.getItem('empresa')==null){
            console.log('no hay datos....');
            return await getData(0, dom, org, web,0,0,0,0, 0).then(res =>{
                sessionStorage.setItem('empresa', JSON.stringify(res.data));
                console.log(res.data)               

                this.setState({
                    webSite: res.data.web[0],
                    sections: res.data.sections
                });
            })
        }else{
            console.log('ya hay datos cargados');
            let data = JSON.parse(sessionStorage.getItem('empresa'));

            console.log(data)

            this.setState({
                webSite: data.web[0],
                sections: data.sections
            });
        }
    }

    render(){

        const{
            webSite, Url, sections
        }=this.state;
       

        return(
            <>
             <Banner />

            <section id="main-container" className="main-container">
                <div className="container">
                    <div className="row text-center">
                    <div className="col-12">
                        <h2 className="section-title">Tipos de formulario</h2>
                        <h3 className="section-sub-title">Contactanos</h3>
                    </div>
                    </div>

                    <div className="row">

                    <div className="col-lg-4 col-md-6">
                        <div className="ts-pricing-box">
                        <div className="ts-pricing-header">
                            <h2 className="ts-pricing-name"><Link to='contacto/proveedores'>Proveedores</Link></h2>                            
                        </div>
                        <div className="ts-pricing-features">
                            <ul className="list-unstyled">
                                <span>Si eres del tipo proveedor</span>                        
                            </ul>
                        </div>
                        <div className="plan-action">
                            <Link to='contacto/proveedores' className="btn btn-dark">Contactanos</Link>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="ts-pricing-box ts-pricing-featured">
                        <div className="ts-pricing-header">
                            <h2 className="ts-pricing-name" ><Link to='contacto/rrhh'>Recursos humanos</Link></h2>                            
                        </div>
                        <div className="ts-pricing-features">
                            <ul className="list-unstyled">
                            <span>Si eres de recursos humanos</span>
                            </ul>
                        </div>
                        <div className="plan-action">
                            <Link to='contacto/rrhh' className="btn btn-dark">Contactanos</Link>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="ts-pricing-box">
                        <div className="ts-pricing-header">
                            <h2 className="ts-pricing-name"><Link to='contacto/colaboradores'>Colaboradores</Link></h2>                            
                        </div>
                        <div className="ts-pricing-features">
                            <ul className="list-unstyled">
                                <span>Si eres un colaborador</span>
                            </ul>
                        </div>
                        <div className="plan-action">
                            <Link to='contacto/colaboradores' className="btn btn-dark">Contactanos</Link>
                        </div>
                        </div>
                    </div>

                    </div>                    

                </div>
            </section>
            </>
        )
    }
}

export default Contacts;